import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'

import VPPLinesSeparator from '_images/svgs/pages/portfolio/vpp/vpp-lines-separator.svg'
import VPPComplexBackendSeparator from '_images/svgs/pages/portfolio/vpp/vpp-complex-backend-separator.svg'
import { AppContext } from '_context'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { Container, Image, Text, ScrollArrow, Heading } from '_atoms'
import { Quote, ProudCases } from '_molecules'
import AESLogo from '_images/svgs/aes-tiete-logo.inline.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const VPPCaseStudy = ({ data }) => {
  const vppCaseStudy = data.contentfulVppCaseStudy

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    if (y <= 90) {
      setNavbarColor(variables['vpp-blue-primary'])
      setNavbarLight(false)
    }
  }, [setNavbarColor, setNavbarLight, y])

  return (
    <Layout navbarColorOverride={variables['vpp-blue-primary']}>
      <SEO
        title={vppCaseStudy.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          vppCaseStudy.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={vppCaseStudy.pageThumbnail.file.url}
      />

      <main className={styles.hero}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div className={styles.heroContent}>
                <Fade distance="10%" bottom>
                  <AESLogo className={styles.heroLogo} />
                </Fade>

                <div className={styles.heroText}>
                  <Fade delay={200} distance="10%" bottom>
                    <Text color="white" size="28" splitParagraphs className={styles.heroParagraph}>
                      {vppCaseStudy.heroDescription.internal.content}
                    </Text>
                  </Fade>
                </div>
                <ScrollArrow className={styles.heroArrow} />
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.heroFixed}>
          <Fade delay={350} distance="20%" bottom>
            <Image {...vppCaseStudy.heroImage.fluid} alt={vppCaseStudy.heroImage.description} />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['vpp-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      <section className={styles.challenge}>
        <Container>
          <Row className={styles.challengeSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeTextsWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h1"
                  color="vppBluePrimary"
                  bold
                  className={classNames(styles.heading, styles.challengeHeading)}
                >
                  {vppCaseStudy.challengeTitle}
                  <Text color="vppGreenAccent" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  className={classNames(styles.description, styles.challengeDescription)}
                  color="dark"
                  splitParagraphs
                >
                  {vppCaseStudy.challengeDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Image
                  src={vppCaseStudy.challengeImage.file.url}
                  alt={vppCaseStudy.challengeImage.description}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />

        <VPPLinesSeparator className={styles.challengeLinesSeparator} />
      </section>

      <section className={styles.innovationJourney}>
        <Fade distance="10%" delay={200} bottom>
          <Col xs={12} sm={12} md={6} lg={6} className={styles.innovationJourneyImageWrapper}>
            <Image
              {...vppCaseStudy.innovationJourneyImage.fluid}
              alt={vppCaseStudy.innovationJourneyImage.description}
            />
          </Col>
        </Fade>

        <Container>
          <Row className={styles.innovationJourneySectionRow}>
            <Col xs={12} sm={12} md={12} lg={12} className={styles.innovationJourneyTextsWrapper}>
              <Fade distance="10%" delay={300} bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.innovationJourneyHeading)}
                >
                  {vppCaseStudy.innovationJourneyTitle}
                  <Text color="vppGreenAccent" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={400} bottom>
                <Text
                  className={classNames(styles.description, styles.innovationJourneyDescription)}
                  color="white"
                  splitParagraphs
                >
                  {vppCaseStudy.innovationJourneyDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['vpp-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.complexBackend}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.complexBackendTextWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h4"
                  color="vppBluePrimary"
                  bold
                  className={classNames(styles.heading, styles.complexBackendHeading)}
                >
                  {vppCaseStudy.complexBackendTitle}
                  <Text color="vppGreenAccent" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  className={classNames(styles.description, styles.complexBackendDescription)}
                  color="white"
                  splitParagraphs
                >
                  {vppCaseStudy.complexBackendDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={1} sm={1} md={1} lg={1} className={styles.complexBackendSeparator}>
              <VPPComplexBackendSeparator />
            </Col>

            <Col xs={11} sm={11} md={5} lg={5} className={styles.complexBackendImagesWrapper}>
              {vppCaseStudy.complexBackendImages.map((image, index) => (
                <Fade distance="10%" key={image.file.url} delay={index * 400} bottom>
                  <Image src={image.file.url} alt={image.description} />
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.intelligentData}>
        <Container className={styles.intelligentDataContentWrapper}>
          <Fade distance="10%" delay={200} bottom>
            <Heading
              type="h4"
              color="white"
              bold
              className={classNames(styles.heading, styles.intelligentDataHeading)}
            >
              {vppCaseStudy.intelligentDataTitle}
              <Text color="vppGreenAccent" className={styles.dot}>
                .
              </Text>
            </Heading>
          </Fade>
          <Fade distance="10%" delay={300} bottom>
            <Text
              className={classNames(styles.description, styles.intelligentDataDescription)}
              color="white"
              splitParagraphs
            >
              {vppCaseStudy.intelligentDataDescription.internal.content}
            </Text>
          </Fade>

          <div className={styles.intelligentDataImageWrapper}>
            <Fade distance="10%" delay={400} bottom>
              <Image
                {...vppCaseStudy.intelligentDataImage.fluid}
                alt={vppCaseStudy.intelligentDataImage.description}
                className={styles.intelligentDataImage}
              />
            </Fade>
          </div>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['vpp-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.testimonial}>
        <Container className={styles.testimonialContentWrapper}>
          <Fade distance="10%" delay={200} bottom>
            <Quote
              large
              text={vppCaseStudy.testimonialDescription.internal.content}
              user={vppCaseStudy.testimonialUser}
              userPhoto={vppCaseStudy.testimonialUserImage.fluid}
              userJob={vppCaseStudy.testimonialJobDescription}
              color="vppBluePrimary"
              mobileComplete
            />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBg}>
          <Container className={styles.resultsContentWrapper}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <div
                  className={classNames(
                    styles.resultsTextWrapper,
                    styles.resultsHeadingTextWrapper
                  )}
                >
                  <Fade distance="10%" delay={200} bottom>
                    <Heading type="h4" color="white" bold>
                      {vppCaseStudy.resultsTitle}
                      <Text color="vppGreenAccent" className={styles.dot}>
                        .
                      </Text>
                    </Heading>
                  </Fade>
                </div>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" delay={400} bottom>
                  <div
                    className={classNames(
                      styles.resultsImageWrapper,
                      styles.resultsImageWrapperFirst
                    )}
                  >
                    <Image
                      src={vppCaseStudy.resultsFirstImage.file.url}
                      alt={vppCaseStudy.resultsFirstImage.description}
                    />
                  </div>
                  <div className={styles.resultsTextWrapper}>
                    <Text color="white">{vppCaseStudy.resultsFirstDescription}</Text>
                    <Text color="vppGreenAccent" bold>
                      {vppCaseStudy.resultsFirstDescriptionAccent}
                    </Text>
                    <Text color="white" className={styles.resultsUrl}>
                      Check it <a href={vppCaseStudy.resultsFirstDescriptionUrl}>here.</a>
                    </Text>
                  </div>
                </Fade>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" delay={600} bottom>
                  <div className={styles.resultsImageWrapper}>
                    <Image
                      src={vppCaseStudy.resultsSecondImage.file.url}
                      alt={vppCaseStudy.resultsSecondImage.description}
                    />
                  </div>
                  <div className={styles.resultsTextWrapper}>
                    <Text color="white">{vppCaseStudy.resultsSecondDescription}</Text>
                    <Text color="vppGreenAccent" bold>
                      {vppCaseStudy.resultsSecondDescriptionAccent}
                    </Text>
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.portfolio}>
        <Fade distance="10%" delay={200} bottom>
          <ProudCases currentProject="VPP" caseStudies={vppCaseStudy.proudCases} extendedCTA />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulVppCaseStudy($locale: String) {
    contentfulVppCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      challengeImage {
        description
        file {
          url
        }
      }
      innovationJourneyTitle
      innovationJourneyDescription {
        internal {
          content
        }
      }
      innovationJourneyImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      complexBackendTitle
      complexBackendDescription {
        internal {
          content
        }
      }
      complexBackendImages {
        description
        file {
          url
        }
      }
      intelligentDataTitle
      intelligentDataDescription {
        internal {
          content
        }
      }
      intelligentDataImage {
        description
        fluid {
          src
          srcSet
        }
      }

      testimonialUser
      testimonialJobDescription
      testimonialDescription {
        internal {
          content
        }
      }
      testimonialUserImage {
        description
        fluid {
          src
          srcSet
        }
      }
      resultsTitle
      resultsFirstDescription
      resultsFirstDescriptionAccent
      resultsFirstDescriptionUrl
      resultsFirstImage {
        description
        file {
          url
        }
      }
      resultsSecondDescription
      resultsSecondDescriptionAccent
      resultsSecondImage {
        description
        file {
          url
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

VPPCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default VPPCaseStudy
